const service = {};

if (window.location.href.startsWith("http://localhost")) {
  service.API_URL = "http://localhost:9001/api";
  service.IMG_URL = "http://localhost:9001";
} else {
  service.API_URL = "https://api-bhavsar.rainbowingzz.com/api";
  service.IMG_URL = "https://api-bhavsar.rainbowingzz.com";
}

export default service;
