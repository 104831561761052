import React, { useEffect, useState } from "react";
import { Row, Col, Card,Button } from "react-bootstrap";
import {
  Container,
  Form,
  CardBody,
  CardSubtitle,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { Constants } from "helpers/constant.js";
import Dropzone from "react-dropzone";
import { getCompressedImages, getImageURL } from "helpers/utils.js";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Images = () => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  document.title = "Display Images | Bhavsar Family";
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const imagesLimit = 10;

  const getAllImages = () => {
    let uri = url.GET_ALL_IMAGES;
    ApiServices.callServiceGet(uri)
      .then((response) => {
        if (response?.result?.type === Constants.SUCCESS) {
          const res = response?.result?.images ?? [];
          setImages(res);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const deleteSelectedImage = (imgIndex) => {
    const updatedImages = [...uploadImages].filter(
      (item, index) => index !== imgIndex
    );
    setUploadImages(updatedImages);
  };
  const deleteImage = (imageId) => {
    console.log("delete Image", imageId);
    if (!imageId) return toastr.error("Unable to delete image", "Error");
    let uri = url.DELETE_IMAGE + imageId;
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `Delete Image`,
      text: `Are you sure you want to delete this image ?`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
    }).then((res) => {
      if (res && res.value) {
        ApiServices.callServiceDelete(uri)
          .then((response) => {
            if (response.result.type == "error") {
              MySwal.fire("", response.result.message, "warning");
            } else {
              MySwal.fire(
                "",
                `Deleted  successfully!`,
                response.result.type == "success" ? "success" : "warning"
              );
              getAllImages();
            }
          })
          .catch((err) => console.log("error", err));
      }
      return;
    });
  };
  const updateImages = () => {
    const MySwal = withReactContent(Swal);
    if (uploadImages?.length === 0)
      return MySwal.fire(
        "Caution",
        "No images to be uploaded, please select at least one",
        "warning"
      );
    try {
      const formData = new FormData();
      uploadImages.map((img) => {
        formData.append("displayImages", img);
      });
      let uri = url.ADD_IMAGES;
      ApiServices.callServicePostWithFormData(uri, formData)
        .then((response) => {
          if (response?.result) {
            //toastr.success(response?.result?.message ? response.result.message : '', "Success")
            MySwal.fire(
              "Display Images Updated successfully",
              ``,
              "success"
            ).then((res) => {
              if (res.value) {
                getAllImages();
                setUploadImages([]);

                // setTimeout(() => {
                //   props?.history.push("/property")
                // }, 1000);
              } else {
              }
            });
          } else {
          }
        })
        .catch((error) =>
          console.log("Add Partner Property API Call Error", error)
        );
    } catch (error) {
      console.log("Add Partner Property API Function Error", error);
    }
  };

  const handleAcceptedFiles = async (files) => {
    await getCompressedImages(files)
      .then((compressedImgs) => {
        const newFiles = compressedImgs.map((file) => {
          console.log("file", file);
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          });
          return file;
        });

        if (newFiles.length === compressedImgs.length) {
          if ([...uploadImages, ...newFiles].length <= imagesLimit)
            return setUploadImages([...uploadImages, ...newFiles]);
          toastr.error("Limit exceeded", "Error");
        }
      })
      .catch((error) => {
        console.log("error =>", error);
        toastr.error(error ?? "Error Occred while uploading images", "Error");
      });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    getAllImages();
  }, []);
  return (
    <div className="page-content">
      <Container fluid>
        {images && images.length > 0 ? (
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col">
                  <>
                    <Row className="p-3">
                      <h5>Uploaded Images</h5>
                    </Row>
                    <Row>
                      {images.map((item, key) => (
                        <div
                          key={key}
                          className="col-lg-4 col-md-auto col-sm-auto"
                        >
                          <Card className="m-0">
                            <img
                              style={{
                                width: "100%",
                                height: "250px",
                                objectFit: "contain",
                                borderRadius: 10,
                              }}
                              className="responsive img-fluid wid-100"
                              src={getImageURL(item?.url)}
                              alt="display-image"
                            />
                          </Card>
                          <div className="m-2 text-right">
                            <Button
                              size="sm"
                              variant="outline-danger"
                              onClick={() => {
                                deleteImage(item?._id ?? null);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fas fa-trash mr-2" />
                              Delete
                            </Button>
                          </div>
                        </div>
                      ))}
                    </Row>
                  </>
                </div>
              </div>
            </Card.Body>
          </Card>
        ) : null}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <h5>Display Photos</h5>
                <CardSubtitle className="mb-3">
                  {" "}
                  Upload App's Home Page Photos here.{" "}
                  <strong>{"(Max 10 images at one time)"}</strong>
                </CardSubtitle>
                <Form>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {uploadImages?.length > 0 && (
                    <div className="dropzone-previews mt-3" id="file-previews">
                      <Row>
                        <Label className="col-form-label col-lg-2">Queue</Label>
                      </Row>
                      <Row className="w-100">
                        {uploadImages.map((file, index) => {
                          return (
                            <Card
                              className={`${
                                uploadImages.length == 1
                                  ? "col-12"
                                  : uploadImages.length == 2
                                  ? "col-6"
                                  : uploadImages.length == 3
                                  ? "col-4"
                                  : "col-3"
                              } mt-1 mb-0 shadow-none  dz-processing dz-image-preview dz-success dz-complete p-0`}
                              key={index + "-file"}
                            >
                              <div
                                className="mx-2 p-2 border"
                                style={{ borderRadius: "5px" }}
                              >
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={file?.name}
                                      src={file?.preview}
                                    />
                                  </Col>
                                  <Col className="text-truncate">
                                    <Link
                                      to="#"
                                      className="text-muted  font-weight-bold"
                                    >
                                      {file?.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{file?.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto">
                                    <a
                                      onClick={() => deleteSelectedImage(index)}
                                    >
                                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                                    </a>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                </Form>

                <div className="text-center mt-4">
                  {/* <button
                      type="button"
                      className="btn btn-primary "
                    >
                      Send Files
                    </button> */}
                  <Button
                    className="fw-bold w-md"
                    variant="success"
                    onClick={updateImages}
                  >
                    Update
                  </Button>
                  &nbsp;&nbsp;
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Images;
