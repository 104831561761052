import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import { loginSchema } from "helpers/validationSchemas";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// import images
import profile from "assets/images/profile-img.png";
import BLIV_Partner from "assets/images/logo192.png";
import * as url from "../../service/url_helper";

//Import config
import { ApiServices } from "service/apiServices";
import { isUserLoggedIn, setSecureToken } from "helpers/utils";
import { Constants } from "helpers/constant";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Login = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };

  //meta title
  document.title = "Login | Bhavsar Family";

  const onSubmit = (values) => {
    try {
      let uri = url.LOGIN_PASSWORD;
      let reqObj = {
        mobileNumber: values?.userName,
        password: values?.password,
      };
      ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (!response.result.token)
            return toastr.error("Error Occured", "Failed");
          setSecureToken(Constants.KEY_USER_TOKEN, response?.result?.token);
          setSecureToken(Constants.KEY_USER_DATA, response?.result?.user);
          toastr.success(
            response?.result?.message ? response.result.message : "",
            "Success"
          );
          setTimeout(() => {
            props?.history.push("/images");
          }, 1500);
        })
        .catch((err) => {
          toastr.error(err ?? "Error Occured", "Failed");

          console.log("catch LOGIN API call", err);
        });
    } catch (error) {
      toastr.error(error ?? "Error Occured", "Failed");
      console.log("catch LOGIN error", error);
    }
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      props.history.push("/images");
    }
  }, []);
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Bhavsar Family.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={BLIV_Partner}
                            alt=""
                            //className="rounded-circle"
                            height="40"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">User Name</Label>
                        <Input
                          name="userName"
                          className="form-control"
                          placeholder="Enter user name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.userName || ""}
                          invalid={
                            touched.userName && errors.userName ? true : false
                          }
                        />
                        {touched.userName && errors.userName ? (
                          <FormFeedback type="invalid">
                            {errors.userName}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-4">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.password && errors.password ? true : false
                          }
                        />
                        {touched.password && errors.password ? (
                          <FormFeedback type="invalid">
                            {errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-success fw-bold btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <button onClick={()=>props?.history?.push({pathname : "/register-send-otp",state : {mobileNumber : values?.mobileNumber}})} color="link" className="btn btn-link p-0 fw-medium">
                    {" "}
                    Signup now{" "}
                  </button>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Made with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Rainbowingzz
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
