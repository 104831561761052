import * as Yup from 'yup';
const isSpace = /^\S+$/
export const loginSchema = Yup.object().shape({
  userName: Yup.string()
    .label('Username')
    .matches(isSpace, 'Spaces are not allowed')
    .required(),
  password: Yup.string()
    .label('Password')
    .matches(isSpace, 'Spaces are not allowed')
    .required(),
});
