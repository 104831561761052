import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import Images from "../pages/Images";
import Business from "pages/Business";

const authProtectedRoutes = [
  { path: "/images", exact: true, component: Images },
  { path: "/business", exact: true, component: Business },

  { path: "/", exact: true, component: () => <Redirect to="/images" /> },
];

const publicRoutes = [
  { path: "/login", exact: true, component: Login },
  { path: "/logout", exact: true, component: Logout },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };
