import { isEmpty } from "lodash"
import { Constants } from "./constant"
import service from "service/constant"
import Compressor from 'compressorjs';


export const isUserLoggedIn = () => {
    let token = getSecureToken(Constants.KEY_USER_TOKEN)
    let userData = getSecureToken(Constants.KEY_USER_DATA)
    if (isEmpty(token) && isEmpty(userData)) {
        return false
    } else {
        return true
    }
}

export const setSecureToken = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
}

export const getSecureToken = (key) => {
    const data = localStorage.getItem(key);
    if (data !== null) {
        return JSON.parse(data);
    } else {
        return false;
    }
}

export const getSmallImage = (rawImage) => {
    return new Promise((resolve, reject) => {
        new Compressor(rawImage, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            maxWidth: 200,
            maxHeight: 200,
            strict: true,
            mimeType: 'image/jpeg',
            success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.      
                const myFile = new File([compressedResult], compressedResult.name, {
                    type: compressedResult.type
                });
                return resolve(myFile)
            },
            error: (error) => {
                return reject(error)

            }
        });
    })
}
export const getMediumImage = (rawImage) => {
    return new Promise((resolve, reject) => {
        new Compressor(rawImage, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            maxWidth: 500,
            maxHeight: 500,
            strict: true,
            mimeType: 'image/jpeg',
            success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.      
                const myFile = new File([compressedResult], compressedResult.name, {
                    type: compressedResult.type
                });
                return resolve(myFile)
            },
            error: (error) => {
                return reject(error)

            }
        });
    })
}
export const getOriginalImage = (rawImage) => {
    return new Promise((resolve, reject) => {
        new Compressor(rawImage, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            maxWidth: 1000,
            maxHeight: 1000,
            strict: true,
            mimeType: 'image/jpeg',
            success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.      
                const myFile = new File([compressedResult], compressedResult.name, {
                    type: compressedResult.type
                });
                return resolve(myFile)
            },
            error: (error) => {
                return reject(error)

            }
        });
    })
}


export const getCompressedImages = (rawImgs) => {
    return new Promise((resolve, reject) => {
        if (rawImgs?.length > 0) {
            let updatedArr = []
            rawImgs.map(async (image, index) => {
                // new Compressor(image, {
                //     quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                //     maxWidth:1000,
                //     maxHeight: 1000,
                //     strict:true,
                //     mimeType:'image/jpeg',
                //     success: (compressedResult) => {
                //     // compressedResult has the compressed file.
                //     // Use the compressed file to upload the images to your server.      
                //         const myFile = new File([compressedResult], compressedResult.name, {
                //             type: compressedResult.type
                //         });
                //         updatedArr.push(myFile)
                //         if(updatedArr.length === rawImgs.length){
                //                 return resolve(updatedArr)
                //         }
                //     },
                //     error : (error) => {
                //         return reject(error)

                //     }
                // });
                await Promise.all([
                    // getOriginalImage(image),
                    getMediumImage(image),
                    // getSmallImage(image)
                ]).then((finalValues) => {
                    console.log("finalValues", finalValues)
                    const finalObj = finalValues[0]
                    // let finalObj = {
                    //     originalNew : finalValues[0],
                    //     medium : finalValues[1],
                    //     small : finalValues[2]
                    // }
                    updatedArr.push(finalObj)
                    if (updatedArr.length === rawImgs.length) {
                        return resolve(updatedArr)
                    }
                }).catch((error) => {
                    return reject(error)
                })
            })
        }
    })

};


export const getImageURL = (path) => {
    if (!path) return null
    return `${service.IMG_URL}${path}`
}


