import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { map } from "lodash";
import * as moment from "moment";
import { Col, Container, Row, Table } from "reactstrap";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { Constants } from "helpers/constant.js";

const BusinessList = (props) => {
  //meta title
  document.title = "Business List |  Bhavsar Family";
  const [registeredBusiness, setBusinesses] = useState([]);

  const getBusiness = () => {
    try {
      let uri = url.GET_ALL_BUSINESS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === Constants.SUCCESS) {
            setBusinesses(response.result.data);
          }
        })
        .catch((error) => {
          console.log("getBusiness API call Error", error);
        });
    } catch (error) {
      console.log("getBusiness API Function Error", error);
    }
  };

  const updateBusiness = (business) => {
    try {
      let uri = url.UPDATE_BUSINESS + business?._id;
      const reqObj = {
        isApproved: !business?.isApproved,
      };
      ApiServices.callServicePutWithBodyData(uri, reqObj)
        .then((response) => {
          if (response?.result?.type === "success") {
            getBusiness();
          }
        })
        .catch((error) => {
          console.log("getBusiness API call Error", error);
        });
    } catch (error) {
      console.log("getBusiness API Function Error", error);
    }
  };
  useEffect(() => {
    getBusiness();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="d-flex justify-content-between align-items-center w-100 m-0 mb-2">
            <Col className="p-0">
              <h4>Business</h4>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive">
                  <Table className="product-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" className="h6">#</th>
                        <th scope="col" className="h6">#Company Name</th>
                        <th scope="col h4">#Owner</th>
                        <th scope="col h4">#Address</th>
                        <th scope="col h4">#Approval</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(registeredBusiness, (business, index) => (
                        <tr key={business._id}>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              {index + 1}
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              {business.companyName}
                            </h5>
                          </td>
                          <td>
                          <h5 className="text-truncate font-size-14">{business?.userId?.name}</h5>
                            <p className="text-muted">
                              Contact : {business?.userId?.mobileNumber}
                            </p>
                          </td>
                          <td> {business?.address}</td>
                          <td>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id={business._id}
                                switch="success"
                                checked={business?.isApproved}
                                onClick={() => {
                                  updateBusiness(business);
                                }}
                              />
                              <label
                                className="mb-0"
                                htmlFor={business._id}
                                id="square-switch-filter"
                                data-on-label="Yes"
                                data-off-label=""
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BusinessList);
