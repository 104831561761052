//LOGIN
export const LOGIN_PASSWORD = "/auth/admin-login";

// IMAGES
export const GET_ALL_IMAGES = "/images";
export const ADD_IMAGES = "/images";
export const DELETE_IMAGE = "/images/";

//Business
export const GET_ALL_BUSINESS = "/business";
export const UPDATE_BUSINESS = "/business/";
